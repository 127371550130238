import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLanguage } from "../contexts/LanguageContext"

const RealEstatesList = ({ realEstates = [] }) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  return (
    <div className="jobs-list">
      {realEstates.map(realEstate => {
        const { id, naslov, slika, vrijediDo } = realEstate
        return (
          <div key={id} className="job-little-wrapper">
            {" "}
            {/* Moved key prop here */}
            {selectedLanguage === "EN" ? (
              naslov === "Prazna stranica" ? (
                <h3 className="about-paragraph estate">
                  *There are currently no properties for sale*
                </h3>
              ) : (
                <Link to={`/${id}`} className="job">
                  <GatsbyImage image={slika.gatsbyImageData} alt={naslov} />
                  <h5>{naslov}</h5>
                  {vrijediDo ? <p>The ad is open until {vrijediDo}.</p> : ""}
                </Link>
              )
            ) : naslov === "Prazna stranica" ? (
              <h3 className="about-paragraph estate">
                *Trenutno nema nekretnina koje su na prodaju*
              </h3>
            ) : (
              <Link to={`/${id}`} className="job">
                <GatsbyImage image={slika.gatsbyImageData} alt={naslov} />
                <h5>{naslov}</h5>
                {vrijediDo ? <p>Oglas je otvoren do {vrijediDo}.</p> : ""}
              </Link>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default RealEstatesList
